import { COLOR_CODES_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const listRequest = () => async () => {
  try {
    let result = await getRequest(COLOR_CODES_API_PATH.LIST_PATH, null, false);
    return result;
  } catch (error) {
    // TODO Handle Error
  }
};

export const createUpdateRequest = (data) => async () => {
  try {
    let result = await postRequest(
      COLOR_CODES_API_PATH.CREATE_UPDATE_PATH,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
