import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { ORDER_API_PATH } from "../../../network/config/apiPaths";
import OrdersItem from "../order-item";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StoreOrdersItem from "./store-order-item";

function StoreOrders(props) {
  const [storeId] = useState(props.id);
  const [orderResult, setOrderResult] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    OrderList();
  }, []);

  async function OrderList() {
    const data = await axios.get(
      API_CONFIG.API_HOST + ORDER_API_PATH.UNIQUE_STORE_ORDERS + storeId,
      {
        headers: API_HEADERS,
      }
    );

    setOrderResult(data.data.result);
  }

  return (
    <>
      {/* orderResult.length===0*/}
      {orderResult === null ? (
        <span>fetching...</span>
      ) : (
        <>
          <div className="row m-3">
            <div className="col-md-3">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter customer mobile no"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <select className="form-scrollbar form-control">
                  <option value="">{"--Select Category--"}</option>
                  <option>D.Executive</option>
                  <option>Status</option>
                  <option>Location</option>
                </select>
              </div>
            </div>

            <div className="col-md-12 card-1">
              <table className="table table-striped table-bordered border-sm  mt-2">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Order Amount</th>
                    <th scope="col">Customer Name </th>
                    <th scope="col">Coupon</th>
                    <th scope="col">Status </th>
                    <th scope="col">Payment Mode </th>
                  </tr>
                </thead>

                <tbody>
                  {orderResult.map((obj) => {
                    return <StoreOrdersItem order={obj} key={obj.id} />;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default StoreOrders;
