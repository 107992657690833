import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { listAction } from "../../network/store/action/SubCategoryResponseAction";
import { listAction as categoryListAction } from "../../network/store/action/SectionResponseAction";
import SubCategoryItem from "./language-item";
import {
  formUpdateData,
  formUpdateStatus,
  imageUploadStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import AddUpdateForm from "./add-update-form";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
import AppLodingBar from "../../components/loader";
import { changeStatus } from "../../redux/slice/loaderSlice";
import LanguageItem from "./language-item";
import { getRequest } from "../../network/service/config/GetRequest";
import { API_HEADERS } from "../../network/config/ApiConfig";
import { axiosApi } from "../../network/service/config/AaxiosUtil";

function Language() {
  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const [sectionList, setSectionList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    sectionId: "",
  });

  const imageUpload = useSelector(imageUploadStatus);
  const formData = useSelector(formUpdateData);

  useEffect(() => {
    getSections();
    loadData();
  }, []);

  useEffect(() => {
    getSections();
    loadData();
  }, [filterOptions]);

  const getSections = async () => {
    // await dispatch(categoryListAction({}, dispatch)).then((response) => {
    //   if (response.result) {
    //     setSectionList(response.result);
    //   }
    // });

    axiosApi
      .get("https://swara.biyss.com/Language/list", {
        headers: false
          ? {
              ...API_HEADERS,
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            }
          : API_HEADERS,
      })
      .then((response) => {
        // success block
        if (response.status === 401) {
          // handle error
        } else {
          // return the response
          return response;
        }
      })
      .catch((error) => {
        // error block
        return error.response;
      });
  };

  const handleChanges = (e) => {
    if (e.target.name === "sectionId") {
      sectionList.findIndex((obj) => obj.id === e.target.value);
      setfilterOptions({
        ...filterOptions,
        sectionId: e.target.value,
      });
    } else {
      setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }
  };
  const loadData = async () => {
    dispatch(changeStatus(true));
    // await dispatch(
    //   listAction(
    //     {
    //       secId: filterOptions.sectionId,
    //     },
    //     dispatch
    //   )
    // ).then((reponse) => {
    //   dispatch(changeStatus(false));
    //   setFilterList([]);

    //   if (reponse.result) {
    //     setResultList(reponse.result);
    //   } else {
    //     setResultList([]);
    //   }
    // });

    axiosApi
      .get("https://swara.biyss.com/Language/list", {
        headers: false
          ? {
              ...API_HEADERS,
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            }
          : API_HEADERS,
      })
      .then((response) => {
        // success block
        if (response.status === 401) {
          // handle error
        } else {
          if (response.result) {
            setResultList(response.result);
          } else {
            setResultList([]);
          }
        }
      })
      .catch((error) => {
        // error block
        setResultList([]);
      });
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);

    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  const onSubmit = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: {} }));
    dispatch(
      updateMessage({
        display: true,
        message: "Record updated",
      })
    );
    setResultList([]);
    setSearchVal("");
    setFilterList([]);

    loadData();
  };

  return (
    <>
      {showRequestForm && (
        <AddUpdateForm
          categories={sectionList}
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Languages</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add SubCategory
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search</label>
              <input
                type="search"
                name="name"
                className="form-control"
                value={searchVal}
                placeholder="Enter Sub category name"
                onChange={(e) => handleSearchClick(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Category</label>
              <select
                name="sectionId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterOptions.sectionId}
              >
                <option value={""}>{"--Select Category--"}</option>
                {sectionList &&
                  sectionList.length > 0 &&
                  sectionList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-12 card-1 table-responsive">
            <table className="table table-striped table-bordered border-sm  mt-2">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Category</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {searchVal === "" && resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <LanguageItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : filterList.length > 0 ? (
                <tbody>
                  {filterList.map((item, i) => {
                    return <LanguageItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default Language;
