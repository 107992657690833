import Attendance from "../attendance/attendance";
import { listAction as storeListAction } from "../../network/store/action/BranchResponseAction";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { TfiReload } from "react-icons/tfi";
import { Tab, Tabs } from "react-bootstrap";
import PendingOrders from "../order/PendingOreders";
import InprogressOrders from "../order/InprogressOreders";
import DeliveredOrders from "../order/DeliveredOreders";
import CancelledOrders from "../order/CancelledOreders";
import RecentOrders from "../order/RecentOreders";
import { randomNumberInRange } from "../../utils/app-constants";

function DBoardOrders() {
  const [branches, setBranchList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    storeId: "",
    refresh: 0,
  });
  const handleChanges = (e) => {
    setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getStores();
  }, []);

  const getStores = async () => {
    console.log("refreshPage", "getStores");
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const refreshPage = () => {
    console.log("refreshPage", "refreshPage");
    setfilterOptions({
      ...filterOptions,
      refresh: randomNumberInRange(),
    });
  };

  return (
    <div className="row">
      <div className="col-md-2">
        <h4 className="card-title mb-4">Orders</h4>
      </div>
      <div className="col-md-2 mr-auto text-end">
        <div className="form-group">
          <select
            name="storeId"
            className="form-select"
            onChange={(e) => handleChanges(e)}
            value={filterOptions.storeId}
          >
            <option value={""}>{"All Branches"}</option>
            {branches &&
              branches.length > 0 &&
              branches.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>

      <div className="col-1">
        <TfiReload size={22} onClick={() => refreshPage()} color="red" />
      </div>

      <Tabs
        defaultActiveKey="today"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="today" title="Today">
          <RecentOrders
            storeId={filterOptions.storeId}
            refresh={filterOptions.refresh}
          />
        </Tab>

        <Tab eventKey="pending" title="Pending">
          <PendingOrders
            storeId={filterOptions.storeId}
            refresh={filterOptions.refresh}
          />
        </Tab>

        <Tab eventKey="inprogress" title="In-Progress">
          <InprogressOrders
            storeId={filterOptions.storeId}
            refresh={filterOptions.refresh}
          />
        </Tab>

        <Tab eventKey="delivered" title="Delivered">
          <DeliveredOrders
            storeId={filterOptions.storeId}
            refresh={filterOptions.refresh}
          />
        </Tab>

        <Tab eventKey="cancelled" title="Cancelled">
          <CancelledOrders
            storeId={filterOptions.storeId}
            refresh={filterOptions.refresh}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default DBoardOrders;
