import { createRoot } from "react-dom/client";
import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
} from "@vis.gl/react-google-maps";

import MarkerWithInfowindow from "./map-marker";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeStatus } from "../../redux/slice/loaderSlice";
import { listAction as storeListAction } from "../../network/store/action/BranchResponseAction";
import { listAction } from "../../network/store/action/UserResponseAction";
import { FaExchangeAlt } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";

function GMapsPage() {
  const dispatch = useDispatch();

  const [branches, setBranchList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    storeId: "",
    dbStatus: "",
  });
  const [resultList, setResultList] = useState([]);

  const [latitude, setLatitude] = useState("13.1186");
  const [longtude, setLongitude] = useState("80.1574");

  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    getStores();
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [filterOptions]);

  const getStores = async () => {
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    if (e.target.name === "storeId") {
      const sectionIdex = branches.findIndex(
        (obj) => obj.id === e.target.value
      );

      if (e.target.value === "") {
        setLatitude("13.1186");
        setLongitude("80.1574");
      } else {
        setLatitude(branches[sectionIdex].latitude);
        setLongitude(branches[sectionIdex].longitude);
      }

      setfilterOptions({
        ...filterOptions,
        storeId: e.target.value,
      });
    } else {
      setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }
  };
  const loadData = async () => {
    dispatch(changeStatus(true));
    await dispatch(
      listAction(
        {
          orgId: filterOptions.storeId,
          roleId: "3,4,7",
          dbStatus: filterOptions.dbStatus,
        },
        dispatch
      )
    ).then((reponse) => {
      dispatch(changeStatus(false));
      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  return (
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row justify-content">
          <div className="col-md-2">
            <div className="form-group">
              <label>Branch</label>
              <select
                name="storeId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterOptions.storeId}
              >
                <option value={""}>{"--Select Branch--"}</option>
                {branches &&
                  branches.length > 0 &&
                  branches.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Status</label>
              <select
                name="dbStatus"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterOptions.dbStatus}
              >
                <option value={""}>{"--Select Status--"}</option>
                <option value={"AVAILABLE"}>{"AVAILABLE"}</option>
                <option value={"BUSY"}>{"BUSY"}</option>
                <option value={"OFF"}>{"OFF"}</option>
              </select>
            </div>
          </div>
          <div className="col-1">
            <div className="form-group">
              <label>Refresh</label>
              <div className="text-left">
                <TfiReload size={28} onClick={() => loadData()} color="red" />
              </div>
            </div>
          </div>
          {/* <div className="mr-auto col-7 text-end">
            <button type="button" className="mr-auto btn btn-purple-bg">
              BACK
            </button>
          </div> */}
        </div>
      </div>

      <div className="col-md-12 card">
        <APIProvider apiKey={"AIzaSyCB14naEHjDiVBFLEqhPBMUA9eqg_4yRdk"}>
          <Map
            style={{ width: "85vw", height: "75vh" }}
            defaultCenter={{
              lat: parseFloat(latitude),
              lng: parseFloat(longtude),
            }}
            defaultZoom={15}
            gestureHandling={"auto"}
            onCameraChanged={(ev) => {
              setLatitude(ev.detail.center.lat);
              setLongitude(ev.detail.center.lng);
            }}
            mapId={"1d5db0dae82995a2"}
            center={{
              lat: parseFloat(latitude),
              lng: parseFloat(longtude),
            }}
            // zoom={15}
          >
            {/* <MapControl position={ControlPosition.TOP_LEFT}>
                <div
                  style={{
                    background: "white",
                    padding: "1em",
                  }}
                >
                  Zoom: {zoom.toFixed(2)}
                </div>
              </MapControl> */}

            {resultList &&
              resultList.length > 0 &&
              resultList.map((item, i) => {
                return <MarkerWithInfowindow item={item} key={item.id} />;
              })}
          </Map>
        </APIProvider>
      </div>
    </main>
  );
}

export default GMapsPage;
