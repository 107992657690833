import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceCreateUpdateAction } from "../../network/store/action/ProductPriceAction";
import { displayForm, updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { listAction as uomListAction } from "../../network/store/action/UomResponseAction";
import { listAction as dimensionListAction } from "../../network/store/action/DimensionResponseAction";
import { listAction as colorListAction } from "../../network/store/action/ColorsResponseAction";
import { useLocation, useNavigate } from "react-router-dom";

const PriceCreateUpdate = (props) => {
  // TO get Callbacks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [uomList, setUomList] = useState([]);
  const [dimensionList, setDimensionList] = useState([]);
  const [colorList, setColorList] = useState([]);

  // Page Form
  const [dataForm, setDataForm] = useState({
    id: "",
    productId: props.id,
    price: "",
    offer: "",
    b2bprice: "",
    b2boffer: "",
    maxQantity: "",
    sku: "",
    measure: "",
    dimension: "",
    colorId: "",
    qty: "",
    pPrice: "",
    sQuantity: "",
    sIndicator: "",
    userMaxQnty: "",
    status: "YES",
  });

  useEffect(() => {
    getUomList();
    getDimensionList();
    getColorList();
  }, []);

  const getUomList = async () => {
    await dispatch(uomListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setUomList(response.result);
      }
    });
  };

  const getDimensionList = async () => {
    await dispatch(dimensionListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setDimensionList(response.result);
      }
    });
  };

  const getColorList = async () => {
    await dispatch(colorListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setColorList(response.result);
      }
    });
  };
  const handleInputChanges = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmtClick = async () => {
    //   if (dataForm.id !== "" && dataForm.storeId !== "") {
    let requestBody = dataForm;
    await dispatch(priceCreateUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        if (props.onSubmitClick) props.onSubmitClick(response.result.id);
      } else {
      }
    });
    //   }
  };

  return (
    <>
      <div className="d-flex" style={{ paddingLeft: "15%" }}>
        <div className="col-lg-10">
          <div className="container">
            <div className="row mt-5 mb-5 card-1" style={{ padding: "30px" }}>
              <h5 className="text-center">Item Price</h5>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>SKU Code</label>
                    <input
                      type="text"
                      name="sku"
                      className="form-control"
                      value={dataForm.sku}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>Quantity</label>
                    <input
                      type="number"
                      name="qty"
                      className="form-control"
                      value={dataForm.qty}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>Measure</label>
                    <select
                      name="measure"
                      className="form-select"
                      onChange={(e) => handleInputChanges(e)}
                      value={dataForm.measure}
                    >
                      <option value={""}>{"--Select UOM--"}</option>
                      {uomList &&
                        uomList.length > 0 &&
                        uomList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                {dimensionList && dimensionList.length > 0 && (
                  <div className="col-md-3">
                    <div className="form-group m-0">
                      <label>Dimension</label>
                      <select
                        name="dimension"
                        className="form-select"
                        onChange={(e) => handleInputChanges(e)}
                        value={dataForm.dimension}
                      >
                        <option value={""}>{"--Select Dimension--"}</option>

                        {dimensionList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}

                {colorList && colorList.length > 0 && (
                  <div className="col-md-3">
                    <div className="form-group m-0">
                      <label>Color</label>
                      <select
                        name="colorId"
                        className="form-select"
                        onChange={(e) => handleInputChanges(e)}
                        value={dataForm.colorId}
                      >
                        <option value={""}>{"--Select Color--"}</option>

                        {colorList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                {/* <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>Purchase / Mfg Cost</label>
                    <input
                      type="number"
                      name="pPrice"
                      className="form-control"
                      value={dataForm.pPrice}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div> */}
                <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>B2C Sale Price</label>
                    <input
                      type="number"
                      name="price"
                      className="form-control"
                      value={dataForm.price}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>B2C Offer%</label>
                    <input
                      type="number"
                      name="offer"
                      className="form-control"
                      value={dataForm.offer}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>B2B Sale Price</label>
                    <input
                      type="number"
                      name="b2bprice"
                      className="form-control"
                      value={dataForm.b2bprice}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>B2B Offer%</label>
                    <input
                      type="number"
                      name="b2boffer"
                      className="form-control"
                      value={dataForm.b2boffer}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div> */}

                <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>Stock Quantity</label>
                    <input
                      type="number"
                      name="sQuantity"
                      className="form-control"
                      value={dataForm.sQuantity}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>Stock Indicator</label>
                    <input
                      type="number"
                      name="sIndicator"
                      className="form-control"
                      value={dataForm.sIndicator}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group m-0">
                    <label>User Max Quantity</label>
                    <input
                      type="number"
                      name="userMaxQnty"
                      className="form-control"
                      value={dataForm.userMaxQnty}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <button
                    style={{ display: "flex", justifyContent: "center" }}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onSubmtClick()}
                  >
                    Submit
                  </button>

                  <button
                    className="btn btn-white-bg"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceCreateUpdate;
