import { REPORTS_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const orderReportRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${REPORTS_API_PATH.ORDER_REPORT_PATH}?storeId=${data.storeId}&fromDate=${data.startDate}&toDate=${data.endDate}&status=${data.status}`,
      null,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
  }
};

export const orderItemsReportRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${REPORTS_API_PATH.ORDER_ITEMS_REPORT_PATH}?storeId=${data.storeId}&fromDate=${data.startDate}&toDate=${data.endDate}&status=${data.status}&productId=${data.productId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
  }
};
