import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUpdateAction } from "../../network/store/action/MediaResponseAction";
import {
  formUpdateData,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../redux/slice/toastSlice";

const AddUpdateForm = (props) => {
  // TO get Callbacks
  const dispatch = useDispatch();
  const formData = useSelector(formUpdateData);
  const itemInfo = formData.item;
  // // Page Form
  const [dataForm, setDataForm] = useState({
    title: "",
    type: "",
    description: "",
    link: "",
  });

  const [types, setTypes] = useState([
    { id: "Instagram", name: "Instagram" },
    { id: "YouTube", name: "YouTube" },
    { id: "Facebook", name: "Facebook" },
  ]);

  useEffect(() => {
    if (itemInfo != undefined) {
      let updateValues = {
        title: itemInfo.title,
        description: itemInfo.description,
        link: itemInfo.link_url,
        type: dataForm.type,
      };
      setDataForm(updateValues);
    }
  }, []);

  const handleInputChanges = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmtClick = async () => {
    let requestBody = {
      title: dataForm.title,
      description: dataForm.description,
      link_url: dataForm.link,
      type: dataForm.type,
      status: "ACTIVE",
    };

    if (itemInfo != undefined) {
      requestBody.id = itemInfo.id;
    }

    console.log("requestBody", requestBody);
    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        if (props.onSubmit) props.onSubmit();
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          zIndex: "999",
          overflow: "scroll",
        }}
      >
        <div className="d-flex align-item-center justify-content-center">
          <div className="col-md-8">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-5 card-1 p-5">
                  {itemInfo != undefined ? (
                    <h4 className="text-center">Update MediaLink</h4>
                  ) : (
                    <h4 className="text-center"> Add MediaLink</h4>
                  )}

                  <div className="col-12 mt-4">
                    <label>Media Type</label>
                    <select
                      className="form-control"
                      name="type"
                      value={dataForm.type}
                      onChange={handleInputChanges}
                    >
                      <option value={""}>{"--Select Media Type--"}</option>

                      {types.map((item, i) => {
                        return (
                          <option key={i} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Title</label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={dataForm.title}
                        onChange={(e) => handleInputChanges(e)}
                      />

                      <label>Description</label>
                      <textarea
                        type="text"
                        name="description"
                        className="form-control"
                        value={dataForm.description}
                        onChange={(e) => handleInputChanges(e)}
                      />

                      <label>Link</label>
                      <input
                        type="text"
                        name="link"
                        className="form-control"
                        value={dataForm.link}
                        onChange={(e) => handleInputChanges(e)}
                      />

                      <div
                        style={{
                          marginTop: 20,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{ display: "flex", justifyContent: "center" }}
                          type="button"
                          className="btn btn-purple-bg"
                          onClick={() => onSubmtClick()}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-white-bg"
                          onClick={() => {
                            dispatch(
                              updateFormInfo({
                                displayForm: false,
                                formData: {},
                              })
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdateForm;
