import { useState } from "react";
import { FaEdit, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  isAfterTime,
  timeBetween,
  timeBetweenHrs,
  timeBetweenMins,
} from "../../utils/dateUtil";

function AttendItem(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const editItem = () => {
    navigate("/b2ba", { state: { item: item } });
  };

  const logPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/alog", {
      state: {
        id: item.id,
        userId: item.user_id,
        branchId: item.branch_id,
        userName: item.name,
        dated: item.dated,
      },
    });
  };
  return (
    <>
      <tr>
        <td> {item.dated} </td>
        <td className="align-middle">{item.name}</td>
        <td className="align-middle">{item.branchName}</td>

        <td className="align-middle">
          {item.time_in}
          <br />
          {item.tin_location}{" "}
        </td>
        {/* <td> {item.tin_location} </td> */}
        <td>
          {" "}
          {item.time_out} <br />
          {item.tout_location}
        </td>
        <td>
          {" "}
          {isAfterTime(item.time_out, item.time_in) ? (
            <>
              <b>{timeBetweenHrs(item.time_out, item.time_in)}</b> {"Hrs "}
              <b>{timeBetweenMins(item.time_out, item.time_in)}</b> Mins.
            </>
          ) : (
            <>In Work</>
          )}
        </td>
        {/* <td> {item.tout_location} </td> */}
        <td
          onClick={() => {
            logPage();
          }}
        >
          <Link>{item.logCount} Entries</Link>
        </td>
      </tr>
    </>
  );
}

export default AttendItem;
