import React, { useEffect, useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { USER_API_PATH } from "../../../network/config/apiPaths";

import StoreAdminItem from "./store-admin-item";
import { changeStatus } from "../../../redux/slice/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  formUpdateData,
  largeImageStatus,
} from "../../../redux/slice/formUpdateSlice";
import FullImageView from "../../../components/full-image";

function StoreAdmin() {
  const navigate = useNavigate();
  const [apiResult, setApiResult] = useState([]);

  const [searchVal, setSearchVal] = useState("");

  const [filterList, setFilterList] = useState([]);

  const formData = useSelector(formUpdateData);
  const displayLargeImage = useSelector(largeImageStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    UserList();
  }, []);

  const UserList = async () => {
    dispatch(changeStatus(true));
    const data = await axios.get(
      API_CONFIG.API_HOST + USER_API_PATH.LIST_PATH + "4",
      {
        headers: API_HEADERS,
      }
    );
    dispatch(changeStatus(false));
    setApiResult(data.data.result);
    setFilterList(data.data.result);
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    const filterBySearch = apiResult.filter((item) => {
      if (item.phone.includes(searchTerm)) {
        return item;
      }
    });
    setFilterList(filterBySearch);
  }

  apiResult.length === 0 && <span>Loading</span>;

  return (
    <>
      {displayLargeImage && (
        <>
          <FullImageView
            product={formData}
            onCancelClick={(reloadStatus) => {}}
          />
        </>
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Store Admin</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  navigate("/addNewUser");
                }}
              >
                + Add
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search</label>
              <input
                type="number"
                name="name"
                className="form-control"
                value={searchVal}
                onChange={handleSearchClick}
                placeholder="Enter Phone No"
              />
            </div>
          </div>

          <div className="col-md-12 card-1 table-responsive">
            <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th scope="col">Name</th>
                  <th scope="col">Contact no</th>
                  <th scope="col">Branch</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>

              <tbody>
                {filterList.map((obj) => {
                  return <StoreAdminItem {...obj} key={obj.id} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default StoreAdmin;
