import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAction } from "../../network/store/action/DimensionResponseAction";
import AddUpdateForm from "./add-update-form";
import {
  formUpdateData,
  formUpdateStatus,
  imageUploadStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import DimensionItem from "./dimension-item";
import { updateMessage } from "../../redux/slice/toastSlice";
import { changeStatus } from "../../redux/slice/loaderSlice";

function Dimension() {
  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  const formData = useSelector(formUpdateData);
  // list of items
  const [resultList, setResultList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  //const selectedCatIds = useSelector(selectedItemsStatus);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    dispatch(changeStatus(true));
    await dispatch(listAction(dispatch)).then((reponse) => {
      dispatch(changeStatus(false));
      if (reponse.result) {
        setResultList(reponse.result);
      }
    });
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);

    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  const onSubmit = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: {} }));
    dispatch(
      updateMessage({
        display: true,
        message: "Record updated",
      })
    );
    setResultList([]);
    setSearchVal("");
    setFilterList([]);

    loadData();
  };

  // const editItem = (item) => {
  //
  //   dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  // };

  return (
    <>
      {showRequestForm && (
        <AddUpdateForm
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Dimension</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Dimension
              </button>
            </div>
          </div>
        </div>

        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search Dimension</label>
              <input
                type="search"
                name="name"
                className="form-control"
                value={searchVal}
                placeholder="Enter dimension"
                onChange={(e) => handleSearchClick(e)}
              />
            </div>
          </div>
          <div className="col-md-12 card-1">
            <table
              className="table table-striped table-bordered border-sm  mt-2"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col" class="col-1">
                    {" "}
                    Id
                  </th>

                  <th scope="col" class="col-3">
                    Dimension
                  </th>
                  <th scope="col" class="col-1">
                    Status
                  </th>
                  <th scope="col" class="col-3">
                    Action
                  </th>
                </tr>
              </thead>
              {searchVal === "" && resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <DimensionItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : filterList.length > 0 ? (
                <tbody>
                  {filterList.map((item, i) => {
                    return <DimensionItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default Dimension;
