import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAjlNwVJthoFC038afsQvun2qRsFqKtzuk",
  authDomain: "lambigov2.firebaseapp.com",
  projectId: "lambigov2",
  storageBucket: "lambigov2.appspot.com",
  messagingSenderId: "575410443256",
  appId: "1:575410443256:web:e40a38128fd6fdc3efbc0d",
  measurementId: "G-4S3EL2FHXK",
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        "BEUgNoh2r0YIfNSo6rXojb6XaTl9iSvNnfbgw4OUyUf7Wur39c7jgmf1pOPL97YnFvid-gzU7Q1kGF9EJlHJiDc",
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
