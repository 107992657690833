import { productImageUplaodRequest } from "../../service/ImageUploadService";

export const productImageRequest = (requestBody) => async (dispatch) => {
  const response = await dispatch(productImageUplaodRequest(requestBody));

  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
