import { useState } from "react";
import { FaEdit, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function OrderSalesItem(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [item, setItem] = useState(props.item);

  return (
    <>
      <tr>
        <td className="align-middle">{item.dated}</td>
        <td className="align-middle">{item.name} Items</td>

        <td className="align-middle">
          {item.qty} {item.measure} - ₹{item.price}
        </td>
        <td className="align-middle">
          {item.totalQty} Nos. -- {item.totalSaleQty} {item.measure}{" "}
        </td>
        <td className="align-middle">₹{item.totalPrice}</td>
        <td className="align-middle">₹{item.totalOffer}</td>
        <td className="align-middle">₹{item.totalTax}</td>

        <td className="align-middle">₹{item.totalSalePrice}</td>
      </tr>
    </>
  );
}

export default OrderSalesItem;
