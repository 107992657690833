import { useState, memo, useRef, useEffect } from "react";
import {
  FaEdit,
  FaEye,
  FaImage,
  FaPlus,
  FaRegEdit,
  FaTrash,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../../../assets/no_image.jpg";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { isDsplayAddProduct } from "../../../utils/userRoles";
import { productPromoImageUplaodRequest } from "../../../network/service/ImageUploadService";
import StoreProductListItem from "./store-product-list-item";
import StorePPriceListItem from "./store-product-price-list-item";
import SpPriceAction from "./store-product-status-action";

const StoreProductListItems = (props) => {
  const [resultList] = useState(props.resultList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <tbody>
      {resultList.map((item, i) => {
        let subjectHandled = false;
        let actionsHandled = false;
        return item.prices && item.prices.length > 0 ? (
          item.prices.map((price, j) => {
            let subjectTdEl;

            let rowCount = item.prices.length;

            if (!subjectHandled) {
              subjectTdEl = (
                <>
                  <td rowSpan={rowCount}>{item.item.id}</td>
                  <td rowSpan={rowCount}>{item.item.id}</td>
                  <td rowSpan={rowCount}>{item.item.name}</td>
                  <td rowSpan={rowCount}>{item.item.hsn}</td>
                </>
              );

              subjectHandled = true;
            }

            let courseTdEl;

            courseTdEl = (
              <>
                <StorePPriceListItem
                  itemPrice={price}
                  productId={item.item}
                  storeId={props.storeId}
                />
              </>
            );

            let actiondTdEl;
            if (!actionsHandled) {
              actiondTdEl = (
                <SpPriceAction
                  product={item}
                  rowCount={rowCount}
                  storeId={props.storeId}
                />
                // <>
                //   <td rowSpan={rowCount}>{item.item.id}</td>
                // </>
              );

              actionsHandled = true;
            }

            return (
              <tr key={j}>
                {subjectTdEl}
                {courseTdEl}
                {actiondTdEl}
              </tr>
            );
          })
        ) : (
          // <tr key={i}>
          //   <td>{item.item.id}</td>
          //   <td>{item.item.id}</td>
          //   <td>{item.item.name}</td>
          //   <td>{item.item.hsn}</td>
          //   <td>N/A</td>
          //   <td>N/A</td>
          //   <td>N/A</td>
          //   <td className="align-middle">
          //     {/* <p>{item.status}</p> */}
          //     <div class="form-check form-switch  col-lg-12 d-flex justify-content-center">
          //       <input
          //         class="form-check-input"
          //         type="checkbox"
          //         role="switch"
          //         onChange={onStatusChange}
          //         checked={itemStatus === "YES" ? true : false}
          //       />
          //     </div>
          //   </td>
          // </tr>

          <StoreProductListItem product={item} storeId={props.storeId} />
        );
      })}
    </tbody>
  );
};

export default StoreProductListItems;
