import { useState } from "react";
import { FaEdit, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function SalesItem(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [item, setItem] = useState(props.item);
  const logPage = () => {
    navigate("/oilog", {
      state: {
        storeId: props.storeId,
        dated: item.dated,
      },
    });
  };
  return (
    <>
      <tr>
        <td
          className="align-middle"
          onClick={() => {
            logPage();
          }}
        >
          <Link>{item.dated}</Link>
        </td>
        <td className="align-middle">{item.totalItems} Items</td>

        <td className="align-middle">₹{item.totalItemsPrice}</td>
        <td className="align-middle">₹{item.totalItemsSalePrice} </td>
        <td className="align-middle">₹{item.totalOfferPrice}</td>
        <td className="align-middle">₹{item.totalTax}</td>
        <td className="align-middle">₹{item.totalActualDeliveryCharge}</td>
        <td className="align-middle">₹{item.totalDeliveryCharge}</td>
        <td className="align-middle">₹{item.totalRoundoff}</td>
      </tr>
    </>
  );
}

export default SalesItem;
