import {
  orderItemsReportRequest,
  orderReportRequest,
} from "../../service/ReportService";

/**
 *
 * @param {*} data  JSON DATA TO SEND TO SERVER
 * @returns API RESULT
 */
export const orderReportAction = (data) => async (dispatch) => {
  const response = await dispatch(orderReportRequest(data));

  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const orderItemsReportAction = (data) => async (dispatch) => {
  const response = await dispatch(orderItemsReportRequest(data));

  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
