import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { ORDER_API_PATH } from "../../network/config/apiPaths";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { INVOICE_PREFIX } from "../../utils/app-constants";
import { orderFormatedDate } from "../../utils/dateUtil";

function OrdersItem(props) {
  // const [date,setDate]=useState(new Date());

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order] = useState(props.order);

  // useEffect(()=>{
  //     const time=setInterval(()=>{
  //         setDate(new Date())
  //     },500);
  //     return clearInterval(time)
  // },[])
  function Temp(status) {
    switch (status) {
      case "0":
        return <span style={{ color: "black" }}>Pending</span>;

      case "1":
        return <span style={{ color: "pinkAccent" }}>Accepted</span>;

      case "2":
        return <span style={{ color: "blueAccent" }}>Assigned</span>;

      case "3":
        return <span style={{ color: "blue" }}>Picked Up</span>;
      case "4":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "5":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "6":
        return <span style={{ color: "red" }}>Cancelled</span>;
      case "7":
        return <span style={{ color: "black" }}>Rejected</span>;
      case "8":
        return (
          <span style={{ color: "0xffcc0d0d" }}>Order Cancel Request</span>
        );
    }
  }

  const storeInfoPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/sInfo", {
      state: {
        storeId: order.storeId,
        storeName: order.storeName,
        storeAddress: order.deliveryCity,
        event: "orders",
      },
    });
  };

  const orderDetailFynctionality = () => {
    //dispatch(updateFormInfo({ displayForm: false, formData: { order } }));

    navigate("/orderDetails", { state: { order } });
  };
  return (
    <tr>
      <td
        onClick={() => {
          orderDetailFynctionality();
        }}
      >
        <Link>
          {INVOICE_PREFIX}
          {order.id}
        </Link>
      </td>
      <td
      // onClick={() => {
      //   storeInfoPage();
      // }}
      >
        {/* <Link>{order.storeName}</Link> */}
        {order.storeName}
      </td>
      <td>{orderFormatedDate(order.created_at)}</td>
      <td>{order.total_roundoff}</td>
      <td>{order.customerName}</td>
      <td>
        {order.address}, {order.landmark}
      </td>
      <td>{order.coupon_type}</td>
      <td>{Temp(order.order_status)}</td>
      <td>
        {order.payment_type} <br></br>{" "}
        {order.pay_option && (
          <>
            <b style={{ fontSize: "12px" }}>
              {order.pay_option} - {order.pay_status}
            </b>
          </>
        )}
      </td>
    </tr>
  );
}

export default OrdersItem;
