import "./App.css";
import Routers from "./Routers";
import { BrowserRouter, HashRouter } from "react-router-dom";
import Sidebar from "./pages/Sidebar/sidebar";
import AppToast from "./components/appToast";
import useNetworkStatus from "./network/netcheckhook";
import { Suspense, useEffect, useState } from "react";
import { changeStatus, loadingStatus } from "./redux/slice/loaderSlice";
import AppLodingBar from "./components/loader";
import { useDispatch, useSelector } from "react-redux";
import AppNotification from "./notifications/token-integration";
import AppFcmToast from "./components/appFcmToast";
import { getMessaging, onMessage } from "firebase/messaging";
import { updateFcmMessage } from "./redux/slice/toastSlice";
import { messaging } from "./notifications/firebase";

function App() {
  const { isOnline } = useNetworkStatus();
  const [isActiveNetwork, setActiveNetwork] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const isLoadingShow = useSelector(loadingStatus);

  const dispatch = useDispatch();

  var timer;
  useEffect(() => {
    if (isOnline) {
      checkActiveNetwork();
    } else {
      dispatch(changeStatus(false));
      setActiveNetwork(false);
      setLoading(true);
    }
  }, [isOnline]);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      dispatch(
        updateFcmMessage({
          display: true,
          title: payload?.notification?.title,
          message: payload?.notification?.body,
        })
      );
    });
  }, []);

  const checkActiveNetwork = async () => {
    //setLoading(true);
    dispatch(changeStatus(true));
    fetch("https://www.google.com/", {
      mode: "no-cors",
    })
      .then(() => {
        setActiveNetwork(true);
        setLoading(false);
        dispatch(changeStatus(false));
        clearTimeout(timer);
      })
      .catch(() => {
        setActiveNetwork(false);
        dispatch(changeStatus(false));
        timer = setTimeout(() => {
          checkActiveNetwork();
        }, 5000);
      });
  };

  return (
    <BrowserRouter>
      <AppNotification />
      {isLoadingShow && <AppLodingBar />}
      {isOnline ? (
        !isLoading ? (
          isActiveNetwork ? (
            <Suspense>
              <Sidebar>
                <Routers />
              </Sidebar>
            </Suspense>
          ) : (
            <>
              <label style={{ fontSize: "24px" }}>
                {" "}
                {isLoading === false ? (
                  <>Network Not Available.</>
                ) : (
                  <>Service Unavailable- No active internet connection.</>
                )}
              </label>
            </>
          )
        ) : (
          <>
            {isLoading === false ? (
              <label style={{ fontSize: "24px" }}>Network Not Available</label>
            ) : (
              <label style={{ fontSize: "24px" }}>Loading..</label>
              // <></>
            )}
          </>
        )
      ) : (
        <>
          <label style={{ fontSize: "24px" }}>Network Not Available</label>
        </>
      )}

      <AppFcmToast />
    </BrowserRouter>
  );
}

export default App;
