import Attendance from "../attendance/attendance";
import { listAction as storeListAction } from "../../network/store/action/BranchResponseAction";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { TfiReload } from "react-icons/tfi";

import { randomNumberInRange } from "../../utils/app-constants";

function DBoardTodayLogins() {
  const [branches, setBranchList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    storeId: "",
    refresh: 0,
  });
  const handleChanges = (e) => {
    setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getStores();
  }, []);

  const getStores = async () => {
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const refreshPage = () => {
    setfilterOptions({
      ...filterOptions,
      refresh: randomNumberInRange(),
    });
  };

  return (
    <div className="row">
      <div className="col-md-2">
        <h4 className="card-title mb-4">Today Logins</h4>
      </div>
      <div className="col-md-2 mr-auto text-end">
        <div className="form-group">
          <select
            name="storeId"
            className="form-select"
            onChange={(e) => handleChanges(e)}
            value={filterOptions.storeId}
          >
            <option value={""}>{"All Branches"}</option>
            {branches &&
              branches.length > 0 &&
              branches.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>

      <div className="col-1">
        <TfiReload size={22} onClick={() => refreshPage()} color="red" />
      </div>

      <div className="table-responsive">
        <Attendance
          storeId={filterOptions.storeId}
          refresh={filterOptions.refresh}
        />
      </div>
    </div>
  );
}

export default DBoardTodayLogins;
