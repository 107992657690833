import React, { useEffect, useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import AttendItem from "./attendance-item";

import { useDispatch, useSelector } from "react-redux";
import { formUpdateStatus } from "../../redux/slice/formUpdateSlice";
import {
  attendanceListAction,
  listAction,
  todayUsersListAction,
} from "../../network/store/action/UserResponseAction";
import { todayDate } from "../../utils/dateUtil";
import TodayAttendItem from "./today-attendance-item";
import { listAction as storeListAction } from "../../network/store/action/BranchResponseAction";
import { TfiReload } from "react-icons/tfi";

function TodayLoginUsers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);
  const [branches, setBranchList] = useState([]);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const refreshTime = 120000; //How frequently you want to refresh the data, in ms

  const [filterOptions, setfilterOptions] = useState({
    storeId: "",
    dated: todayDate(),
    dbStatus: "",
  });

  useEffect(() => {
    const comInterval = setInterval(function () {
      loadData();
    }, refreshTime); //This will refresh the data at regularIntervals of refreshTime
    return () => clearInterval(comInterval); //Clear interval on component unmount to avoid memory leak
  }, []);

  useEffect(() => {
    loadData();
  }, [filterOptions]);

  useEffect(() => {
    getStores();
    loadData();
  }, []);

  const getStores = async () => {
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const loadData = async () => {
    setResultList([]);
    setIsLoading(true);
    await dispatch(
      todayUsersListAction(
        {
          dated: filterOptions.dated,
          storeId: filterOptions.storeId,
          endDate: "",
          role: "3,4,7",
          dbStatus: filterOptions.dbStatus,
        },
        dispatch
      )
    ).then((reponse) => {
      setFilterList([]);
      setIsLoading(false);
      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  const handleChanges = (e) => {
    if (e.target.name === "storeId") {
      setfilterOptions({
        ...filterOptions,
        storeId: e.target.value,
      });
    } else {
      setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }
  };

  return (
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row justify-content">
          <div className="col-md-2">
            <div className="form-group">
              <label>Branch</label>
              <select
                name="storeId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterOptions.storeId}
              >
                <option value={""}>{"--Select Branch--"}</option>
                {branches &&
                  branches.length > 0 &&
                  branches.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {/* <div className="col-md-2">
            <div className="form-group">
              <label>Status</label>
              <select
                name="dbStatus"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterOptions.dbStatus}
              >
                <option value={""}>{"--Select Status--"}</option>
                <option value={"AVAILABLE"}>{"AVAILABLE"}</option>
                <option value={"BUSY"}>{"BUSY"}</option>
                <option value={"OFF"}>{"OFF"}</option>
              </select>
            </div>
          </div>
          <div className="col-1">
            <div className="form-group">
              <label>Refresh</label>
              <div className="text-left">
                <TfiReload size={28} onClick={() => loadData()} color="red" />
              </div>
            </div>
          </div> */}
          {/* <div className="mr-auto col-10 text-end">
            <button type="button" className="mr-auto btn btn-purple-bg">
              BACK
            </button>
          </div> */}
        </div>
      </div>
      <div className="row m-3">
        <div className="col-md-12 card-1 table-responsive">
          <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Emp ID</th>
                <th scope="col">Employee Name</th>
                <th scope="col">Branch</th>
                <th scope="col">Date</th>
                <th scope="col">Login Time</th>
                <th scope="col">Logout Time</th>
                <th scope="col">Work Hours</th>
                {/* <th scope="col">Total Entries</th> */}
              </tr>
            </thead>

            {searchVal === "" && resultList && resultList.length > 0 ? (
              <tbody>
                {resultList.map((item, i) => {
                  return (
                    <TodayAttendItem
                      item={item}
                      key={item.id}
                      dated={filterOptions.dated}
                    />
                  );
                })}
              </tbody>
            ) : filterList.length > 0 ? (
              <tbody>
                {filterList.map((item, i) => {
                  return <TodayAttendItem item={item} key={item.id} />;
                })}
              </tbody>
            ) : isLoading ? (
              <p>Loading..</p>
            ) : (
              <p>No Data found</p>
            )}
          </table>
        </div>
      </div>
    </main>
  );
}

export default TodayLoginUsers;
