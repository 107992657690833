import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getFirebaseToken, onForegroundMessage } from "./firebase";
import messageSound from "../assets/sound/message.mp3";
import { useDispatch } from "react-redux";
import { updateFcmMessage, updateMessage } from "../redux/slice/toastSlice";
import { updateUserActon } from "../network/store/action/UserResponseAction";

function AppNotification() {
  const dispatch = useDispatch();

  const updateNotificationToken = async (token) => {
    let requestBody = {
      token: token,
      id: localStorage.getItem("userId"),
      osType: "WEB",
    };

    await dispatch(updateUserActon(requestBody)).then((response) => {
      console.log(response);
    });
  };
  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      getFirebaseToken()
        .then((firebaseToken) => {
          console.log("Firebase token: ", firebaseToken);
          if (
            firebaseToken &&
            (localStorage.getItem("userId") !== undefined ||
              localStorage.getItem("userId") !== "")
          ) {
            updateNotificationToken(firebaseToken);
          }
        })
        .catch((err) =>
          console.error(
            "An error occured while retrieving firebase token. ",
            err
          )
        );
      //We can send token to server
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  useEffect(() => {
    // onForegroundMessage()
    //   .then((payload) => {
    //     dispatch(
    //       updateFcmMessage({
    //         display: true,
    //         title: payload?.notification?.title,
    //         message: payload?.notification?.body,
    //       })
    //     );
    //     // setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
    //     // if (document.hasFocus()) {

    //     // }
    //   })
    //   .catch((err) => console.log("failed: ", err));
    requestPermission();
  }, []);

  return <></>;
}

export default AppNotification;
