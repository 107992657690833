import React, { useEffect, useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import AttendItem from "./attendance-item";

import { useDispatch, useSelector } from "react-redux";
import { formUpdateStatus } from "../../redux/slice/formUpdateSlice";
import {
  attendanceListAction,
  listAction,
} from "../../network/store/action/UserResponseAction";
import { todayDate } from "../../utils/dateUtil";

function Attendance(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const refreshTime = 60000; //How frequently you want to refresh the data, in ms

  const [filterOptions, setfilterOptions] = useState({
    storeId: "",
    dated: todayDate(),
  });

  // useEffect(() => {
  //   const comInterval = setInterval(function () {
  //     loadData();
  //   }, refreshTime); //This will refresh the data at regularIntervals of refreshTime
  //   return () => clearInterval(comInterval); //Clear interval on component unmount to avoid memory leak
  // }, []);

  useEffect(() => {
    loadData();
  }, [filterOptions, props.storeId, props.refresh]);

  const loadData = async () => {
    setResultList([]);
    setIsLoading(true);
    await dispatch(
      attendanceListAction(
        {
          dated: filterOptions.dated,
          storeId: props.storeId,
          endDate: "",
          userId: "",
        },
        dispatch
      )
    ).then((reponse) => {
      setFilterList([]);
      setIsLoading(false);
      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  return (
    <div className="row m-3">
      <div className="col-md-12 card-1 table-responsive">
        <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Employee Name</th>
              <th scope="col">Branch</th>
              <th scope="col">Login Time</th>
              <th scope="col">Logout Time</th>
              <th scope="col">Work Hours</th>
              <th scope="col">Total Entries</th>
            </tr>
          </thead>

          {searchVal === "" && resultList && resultList.length > 0 ? (
            <tbody>
              {resultList.map((item, i) => {
                return <AttendItem item={item} key={item.id} />;
              })}
            </tbody>
          ) : filterList.length > 0 ? (
            <tbody>
              {filterList.map((item, i) => {
                return <AttendItem item={item} key={item.id} />;
              })}
            </tbody>
          ) : isLoading ? (
            <p>Loading..</p>
          ) : (
            <p>No Data found</p>
          )}
        </table>
      </div>
    </div>
  );
}

export default Attendance;
