import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { getInfoRequest } from "../../network/service/DashboardService";
import { todayDate, todayDateDisplay } from "../../utils/dateUtil";
import Clock from "../../utils/clock";

import DBoardTodayLogins from "./today-logins";
import DBoardOrders from "./dashbord-orders";
import { updateFcmMessage } from "../../redux/slice/toastSlice";
const { RangePicker } = DatePicker;

function Dashboard() {
  const dispatch = useDispatch();
  const [dashboardInfo, setDashBoardInfo] = useState({});

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    await dispatch(getInfoRequest()).then((reponse) => {
      if (reponse.data != null && reponse.data.result) {
        setDashBoardInfo(reponse.data.result);
      }
    });
  };

  return (
    <main className="container-fluid dashboard" style={{ marginTop: "20px" }}>
      <div className="row">
        {/* <div className="col-md-12">
          <h3 className="dashboard-title">DASHBOARD</h3>
        </div> */}

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-4">
                  <div className="card overflow-hidden">
                    <div className="bg-soft" style={{ background: "#028b92" }}>
                      <div className="row">
                        <div className="col-7">
                          <div className="text-primary p-3">
                            <h6 className="text-white">Welcome Back!</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-body pt-0"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="row">
                        <div className="col-sm-7">
                          <h5 className="font-size-15 text-truncate">
                            {localStorage.getItem("userName")}
                          </h5>
                          <p className="text-muted mb-0 text-truncate">
                            {localStorage.getItem("userPhone")}
                          </p>
                        </div>

                        <div className="col-sm-5">
                          <div className="pt-4">
                            <div className="mt-4">{todayDateDisplay()}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card mini-stats-wid">
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Branches</p>
                              <h4 className="mb-0">
                                {dashboardInfo.totalStoresCount}
                              </h4>
                            </div>

                            <div className="flex-shrink-0 align-self-center">
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                <span className="avatar-title">
                                  <i className="bx bx-list-ul font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mini-stats-wid">
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Products</p>
                              <h4 className="mb-0">
                                {" "}
                                {dashboardInfo.totalProducts}
                              </h4>
                            </div>

                            <div className="flex-shrink-0 align-self-center ">
                              <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i className="bx bx-upload font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mini-stats-wid">
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Employees</p>
                              <h4 className="mb-0">
                                {dashboardInfo.totalEmployees}
                              </h4>
                            </div>

                            <div className="flex-shrink-0 align-self-center">
                              <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i className="bx bx-copy-alt font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-4">
                      <div className="card mini-stats-wid">
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Customers</p>
                              <h4 className="mb-0">
                                {dashboardInfo.totalCustomers}
                              </h4>
                            </div>

                            <div className="flex-shrink-0 align-self-center">
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                <span className="avatar-title">
                                  <i className="bx bx-list-ul font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mini-stats-wid">
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                Total Orders
                              </p>
                              <h4 className="mb-0">
                                {dashboardInfo.totalOrders}
                              </h4>
                            </div>

                            <div className="flex-shrink-0 align-self-center ">
                              <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i className="bx bx-upload font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mini-stats-wid">
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                Today Orders
                              </p>
                              <h4 className="mb-0">
                                {dashboardInfo.todayOrders}
                              </h4>
                            </div>

                            <div className="flex-shrink-0 align-self-center">
                              <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i className="bx bx-copy-alt font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginTop: "10px" }}>
                <div className="card-body">
                  <DBoardOrders />
                </div>
              </div>

              <div className="card" style={{ marginTop: "10px" }}>
                <div className="card-body">
                  <DBoardTodayLogins />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
