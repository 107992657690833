import { useState } from "react";
import { FaImage, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/BranchResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";

const StoreItem = (props) => {
  const [item] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editItem = () => {
    // dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
    navigate("/scu", { state: { item } });
  };

  const storeInfoPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/sInfo", {
      state: {
        storeId: item.id,
        storeName: item.name,
        storeAddress: item.address,
        event: "orders",
      },
    });
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        item.status = status;
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{item.id}</p>
      </td>

      <td
        className="align-middle"
        onClick={() => {
          storeInfoPage();
        }}
      >
        <Link>
          <p>{item.name}</p>
        </Link>
      </td>

      <td className="align-middle" style={{ width: "30%" }}>
        <p>{item.address}</p>
      </td>

      <td className="align-middle">
        <div className="col-12">
          <p>{item.contact_name}</p>
        </div>
      </td>
      <td className="align-middle">
        <p>
          {item.contact_one}, {item.contact_two}
        </p>
      </td>
      <td className="align-middle">
        <p>{item.mail}</p>
      </td>

      <td className="align-middle">
        <p>{item.radius} KM</p>
      </td>
      <td className="align-middle">
        <p>{item.delivery_charge}</p>
      </td>
      <td className="align-middle">
        <p>{item.free_delivery_min}</p>
      </td>

      <td className="align-middle">
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>
      <td className="align-middle col-lg-12 d-flex justify-content-center">
        <p>
          <span
            className="edit-icon-btn"
            onClick={() => {
              editItem();
            }}
          >
            <FaRegEdit />
          </span>
          {/* <span className="delete-icon-btn">
            <FaTrash />
          </span> */}
        </p>
      </td>
    </tr>
  );
};

export default StoreItem;
