export const USER_API_PATH = {
  LOGIN_PATH: "Auth/login",
  REGISTRATION_PATH: "Auth/addUser",
  UPDATE_USER: "User/update",
  UPDATE_USER_PWD: "User/updatePassword",
  USER_INFO: "User/info?id=",
  LIST_PATH: "User/listUsers?roleId=",
  IMAGE_UPLOAD: "User/imageUpload",
};
export const BANNER_API_PATH = {
  LIST_PATH: "Banner/list",
  CREATE_UPDATE_PATH: "Banner/update",
  BANNER_IMAGE: "Banner/bannerUpload",
  BANNER_STORES_CONFIG: "Banner/updateStoreBanners",
  DELETE_BANNER: "Banner/delete?id=",
};

export const SECTION_API_PATH = {
  LIST_PATH: "Section/list",
  CREATE_UPDATE_PATH: "Section/update",
  PRODUCT_IMAGE: "Section/imageUpload",
  ICON_IMAGE: "Section/iconUpload",
  STORE_SECTIONS_PATH: "Section/storeSections",
  UPDATE_STORE_SECTION: "StoreSection/update",
};

export const MEDIA_API_PATH = {
  LIST_PATH: "Media/list",
  CREATE_UPDATE_PATH: "Media/update",
  PRODUCT_IMAGE: "Media/imageUpload",
  DELETE_PATH: "Media/delete?id=",
};

export const SUB_CATGORY_API_PATH = {
  LIST_PATH: "Category/list",
  CREATE_UPDATE_PATH: "Category/update",
  PRODUCT_IMAGE: "Category/imageUpload",
};

export const DIMENSIONS_API_PATH = {
  LIST_PATH: "Dimension/list",
  CREATE_UPDATE_PATH: "Dimension/update",
};

export const TAG_API_PATH = {
  LIST_PATH: "Tag/list",
  CREATE_UPDATE_PATH: "Tag/update",
  CLEAR_PRODUCT_TAGS_PATH: "Tag/clearProductTags",
  TAG_IMAGE: "Tag/imageUpload",
};

export const BRAND_API_PATH = {
  LIST_PATH: "Brand/list",
  CREATE_UPDATE_PATH: "Brand/update",
};

export const PRODUCT_API_PATH = {
  LIST_PATH: "Product/adminItemList",
  PRODUCT_CREATE: "Product/update",
  PRODUCT_PRICE: "ProductPrice/update",
  PRODUCT_STATUS: "Product/updateProductStatus",
  PRODUCT_IMAGE: "ProductImage/imageUpload",
  PRODUCT_PREVIEW_IMAGE: "Product/imageUpload",
  PRODUCT_PRICE_LIST: "ProductPrice/list",
  STORE_PRODUCT_PRICE_LIST: "ProductPrice/stroePrices",
  UPDATE_STORE_PRICES: "ProductPrice/updateStorePrices",
  UPDATE_SINGLE_STORE_PRICES: "ProductPrice/updateSingleStorePrices",
  UPDATE_ALL_STORE_SINGLE_PRICE: "ProductPrice/updateSinglePriceStores",
  STORE_PRODUCT_LIST_PATH: "Product/storeItemList",
  STORE_PRODUCT_STATUS: "Product/updateStoreProduct",
  PRODUCT_IMAGE_LIST: "Product/productImages",
  TAG_LIST_PATH: "Tag/productTags",
  PRODUCT_TAGS_UPDATE: "Product/updateTags",
  PRODUCT_TAG_UPDATE: "Product/updateTag",
};

export const ORDER_API_PATH = {
  // LIST_PATH: "Order/adminList",
  // CREATE_UPDATE_PATH: "Order/update",

  ORDER_LIST: "Order/list",
  ORDER_DETAILS: "Order/orderInfo?orderId=",
  UNIQUE_STORE_ORDERS: "Order/storeOrders?storeId=",
  UPDATE_ORDER_STATUS: "Order/updateOrder",
  ORDER_INVOICE: "Order/invoiceUpload",
};

export const TAX_SLAB_API_PATH = {
  LIST_PATH: "TaxSlab/list",
  CREATE_UPDATE_PATH: "TaxSlab/update",
};

export const HSN_CODES_API_PATH = {
  LIST_PATH: "HsnCode/list",
  CREATE_UPDATE_PATH: "HsnCode/update",
};

export const COLOR_CODES_API_PATH = {
  LIST_PATH: "MaterialColor/list",
  CREATE_UPDATE_PATH: "MaterialColor/update",
};

export const MATERIAL_TYPE_API_PATH = {
  LIST_PATH: "MaterialType/list",
  CREATE_UPDATE_PATH: "MaterialType/update",
};

export const UOM_API_PATH = {
  LIST_PATH: "Uom/list",
  CREATE_UPDATE_PATH: "Uom/update",
};

export const BRANCH_API_PATH = {
  LIST_PATH: "Store/list",
  CREATE_UPDATE_PATH: "Store/update",
  MAIN_BRANCH_PATH: "Store/getMainBranch",
};

export const COUPONS_API_PATH = {
  COUPON_LIST_PATH: "PromoCode/list?type=",
  CREATE_COUPON: "PromoCode/update",
  DELETE_COUPON: "PromoCode/delete?id=",
  IMAGE_UPLOAD: "PromoCode/imageUpload",
  UPDATE_STATUS: "PromoCode/updateStatus",
};

export const DASHBOARD_API_PATH = {
  DASHBOARD_DATA_PATH: "Dashboard/getInfo",
};

export const ATTENDANCE_API_PATH = {
  LIST_PATH: "Attendance/list",
  LIST_LOG_PATH: "Attendance/logList",
  TODAY_USERS_LIST: "User/listUsersAttendance",
};

export const REPORTS_API_PATH = {
  ORDER_REPORT_PATH: "Order/orderReport",
  ORDER_ITEMS_REPORT_PATH: "Order/orderItemSales",
};

export const APP_VERSION_API_PATH = {
  APP_LIST_PATH: "AppVersion/appVersionInfo",
  UPDATE_APP: "AppVersion/update",
};
