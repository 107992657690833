import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction as categoryListAction } from "../../network/store/action/SectionResponseAction";
import { listAction as brandListAction } from "../../network/store/action/BrandResponseAction";
import { listAction as tagListAction } from "../../network/store/action/TagResponseAction";

import { useNavigate } from "react-router-dom";
import { updateMessage } from "../../redux/slice/toastSlice";
import { allListRequest } from "../../network/service/SubCategoryService";
import { createUpdateRequest } from "../../network/service/BannerService";

function BannerAdd(props) {
  // to get api data

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandnList, setBrandList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const bannerTypeList = [
    {
      displayName: "Home",
      name: "HOME",
    },
    {
      displayName: "Medium",
      name: "MEDIUM",
    },
    {
      displayName: "Small",
      name: "SMALL",
    },
    {
      displayName: "Large",
      name: "LARGE",
    },
    {
      displayName: "Product",
      name: "PRODUCT",
    },
    {
      displayName: "Web",
      name: "WEB",
    },
  ];

  const optionList = [
    {
      displayName: "Section",
      name: "Section",
    },
    {
      displayName: "Category",
      name: "Category",
    },
    {
      displayName: "Brand",
      name: "Brand",
    },
    {
      displayName: "Promo",
      name: "Promo",
    },
    {
      displayName: "Tag",
      name: "Tag",
    },
    {
      displayName: "Web",
      name: "Web",
    },
  ];

  const [productAddForm, setproductAddForm] = useState({
    name: "",
    bannerOption: "",
    bannerOptionId: "",
    type: "",
    promoUrl: "",
  });

  useEffect(() => {
    getSections();
    getCategories();
    getBrandList();
    getTagList();
  }, []);

  const getCategories = async () => {
    await dispatch(allListRequest({}, dispatch)).then((response) => {
      if (response.data.result) {
        setCategoryList(response.data.result);
      }
    });
  };

  const getTagList = async () => {
    await dispatch(tagListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setTagList(response.result);
      }
    });
  };

  const getBrandList = async () => {
    await dispatch(brandListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBrandList(response.result);
      }
    });
  };

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
  };

  const onSubmtClick = async () => {
    if (checkValidation()) {
      let itemObj = {
        name: productAddForm.name,
        bannerOption: productAddForm.bannerOption,
        bannerOptionId: productAddForm.bannerOptionId,
        type: productAddForm.type,
        promoUrl: productAddForm.promoUrl,
      };

      await dispatch(createUpdateRequest(itemObj, dispatch)).then(
        (response) => {
          if (response.data.result) {
            dispatch(
              updateMessage({
                display: true,
                message: "Banner Created.",
              })
            );
            if (props.onSubmitClick)
              props.onSubmitClick(response.data.result.id);
          }
        }
      );
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Form Error.",
        })
      );
    }
  };

  const checkValidation = () => {
    var isFormValid = true;
    if (
      !productAddForm.name ||
      !productAddForm.bannerOption ||
      !productAddForm.type
    ) {
      isFormValid = false;
    }
    return isFormValid;
  };

  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row justify-content-around tble">
          <div className="container col-md-8  card-1">
            <div>
              <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                <h3 className="dashboard-title">Create Banner</h3>
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Banner Type</label>
                  <select
                    name="type"
                    className="form-select"
                    onChange={(e) => handleChanges(e)}
                    value={productAddForm.type}
                  >
                    <option value={""}>{"--Select Banner Type --"}</option>
                    {bannerTypeList &&
                      bannerTypeList.length > 0 &&
                      bannerTypeList.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.displayName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Banner Option</label>
                  <select
                    name="bannerOption"
                    className="form-select"
                    onChange={(e) => handleChanges(e)}
                    value={productAddForm.bannerOption}
                  >
                    <option value={""}>{"--Select Option--"}</option>
                    {optionList &&
                      optionList.length > 0 &&
                      optionList.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.displayName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              {productAddForm.bannerOption === "Section" && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Section</label>
                    <select
                      name="bannerOptionId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.bannerOptionId}
                    >
                      <option value={""}>{"--Select Section--"}</option>
                      {sectionList &&
                        sectionList.length > 0 &&
                        sectionList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              )}

              {productAddForm.bannerOption === "Category" && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Category</label>
                    <select
                      name="bannerOptionId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.bannerOptionId}
                    >
                      <option value={""}>{"--Select Category--"}</option>
                      {categoryList &&
                        categoryList.length > 0 &&
                        categoryList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              )}
              {productAddForm.bannerOption === "Brand" && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Brand</label>
                    <select
                      name="bannerOptionId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.bannerOptionId}
                    >
                      <option value={""}>{"--Select Brand--"}</option>
                      {brandnList &&
                        brandnList.length > 0 &&
                        brandnList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              )}
              {productAddForm.bannerOption === "Tag" && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Brand</label>
                    <select
                      name="bannerOptionId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.bannerOptionId}
                    >
                      <option value={""}>{"--Select Tag--"}</option>
                      {tagList &&
                        tagList.length > 0 &&
                        tagList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-md-4">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={productAddForm.name}
                    onChange={(e) => handleChanges(e)}
                  />
                </div>
              </div>
              {(productAddForm.bannerOption === "Promo" ||
                productAddForm.bannerOption === "Web") && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Promo / Web URL</label>
                    <input
                      type="text"
                      name="promoUrl"
                      className="form-control"
                      value={productAddForm.promoUrl}
                      onChange={(e) => handleChanges(e)}
                    />
                  </div>
                </div>
              )}

              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ display: "flex", justifyContent: "center" }}
                  type="button"
                  className="btn btn-purple-bg"
                  onClick={() => onSubmtClick()}
                >
                  Submit
                </button>
                <button
                  className="btn btn-white-bg"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default BannerAdd;
