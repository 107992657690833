import { listRequest, createUpdateRequest } from "../../service/ColorService";

/**
 *
 * @param {*} data  JSON DATA TO SEND TO SERVER
 * @returns API RESULT
 */
export const listAction = () => async (dispatch) => {
  const response = await dispatch(listRequest());

  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const createUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(createUpdateRequest(requestBody));

  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
