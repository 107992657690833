import { useState } from "react";
import { FaEdit, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  isAfterTime,
  timeBetweenHrs,
  timeBetweenMins,
} from "../../utils/dateUtil";

function AttendLogItem(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const editItem = () => {
    navigate("/b2ba", { state: { item: item } });
  };

  return (
    <>
      <tr>
        <td> {item.id} </td>
        <td className="align-middle">{item.name}</td>
        <td className="align-middle">{item.branchName}</td>
        <td>
          {isAfterTime(item.time_out, item.time_in) ? (
            <>
              <b>{timeBetweenHrs(item.time_out, item.time_in)}</b> {"Hrs "}
              <b>{timeBetweenMins(item.time_out, item.time_in)}</b> Mins.
            </>
          ) : (
            <>In Work</>
          )}
        </td>
        <td>{item.time_in}</td>
        <td> {item.tin_location} </td>
        <td> {item.time_out} </td>
        <td> {item.tout_location} </td>
      </tr>
    </>
  );
}

export default AttendLogItem;
