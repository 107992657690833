import { BANNER_API_PATH } from "../config/apiPaths";
import { deleteRequest } from "./config/DeleteRequest";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const listRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${BANNER_API_PATH.LIST_PATH}?type=${data.type}&storeId=${data.storeId}&limit=${data.limit}&offset=${data.offset}`,
      null,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
  }
};

export const createUpdateRequest = (data) => async () => {
  try {
    let result = await postRequest(
      BANNER_API_PATH.CREATE_UPDATE_PATH,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const updateStoreBanners = (data) => async () => {
  try {
    let result = await postRequest(
      BANNER_API_PATH.BANNER_STORES_CONFIG,
      data,
      false
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const deleteBanner = (data) => async () => {
  try {
    let result = await deleteRequest(
      `${BANNER_API_PATH.DELETE_BANNER}?id=${data.id}`,
      null,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
