import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    loading: false,
    subMenuNavStatus: false,
  },
  reducers: {
    changeStatus: (state, data) => {
      state.loading = data.payload;
    },
    changeSubMenuNavStatus: (state, data) => {
      state.subMenuNavStatus = data.payload;
    },
  },
});

export const { changeStatus, changeSubMenuNavStatus } = loaderSlice.actions;

export const loadingStatus = (state) => state.rootReducer.loaderReducer.loading;

export const subNavStatus = (state) =>
  state.rootReducer.loaderReducer.subMenuNavStatus;

export default loaderSlice.reducer;
