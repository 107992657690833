import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listAction,
  storeListAction,
} from "../../../network/store/action/ProductResponseAction";
import { listAction as categoryListAction } from "../../../network/store/action/SectionResponseAction";
import { listAction as tagListAction } from "../../../network/store/action/TagResponseAction";
import GMCTPagination from "../../../components/pagination";
import ImageUpload from "../../../components/image-upload";
import { useNavigate } from "react-router-dom";
import ProductListItemTwo from "../product-list-item";
import {
  formUpdateData,
  formUpdateStatus,
  imageUploadStatus,
  largeImageStatus,
} from "../../../redux/slice/formUpdateSlice";
import { isDsplayAddProduct, isStoreLogin } from "../../../utils/userRoles";
import FullImageView from "../../../components/full-image";
import TagProductListItem from "./tag-product-list-item";

function MapTagProducts(props) {
  // to get api data

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  const imageUpload = useSelector(imageUploadStatus);
  const formData = useSelector(formUpdateData);
  const displayLargeImage = useSelector(largeImageStatus);

  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  // list of items
  const [resultList, setResultList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [productAddForm, setproductAddForm] = useState({
    sectionId: "",
    categoryId: "",
    tagId: props.id,
    storeId: 0,
  });

  const [isPriceUpdate, setIsPriceUpdate] = useState(false);
  const [isProductUpdate, setIsProductUpdate] = useState(false);

  useEffect(() => {
    getSections();
    getTags();
    getProducts(
      pageLimit,
      pageOffset,
      productAddForm.sectionId,
      productAddForm.categoryId,
      productAddForm.storeId
    );
  }, []);

  useEffect(() => {
    getProducts(
      pageLimit,
      pageOffset,
      productAddForm.sectionId,
      productAddForm.categoryId,
      productAddForm.storeId
    );
  }, [pageLimit, pageOffset, productAddForm, isPriceUpdate, isProductUpdate]);

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const getTags = async () => {
    await dispatch(tagListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setTagList(response.result);
      }
    });
  };

  const getProducts = async (limit, offset, sectionId, categoryId, storeId) => {
    await dispatch(
      listAction(
        {
          limit: limit,
          offset: offset,
          sectionId: sectionId,
          categoryId: categoryId,
          storeId: storeId,
          tagId: "",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        setResultList([]);
        setResultList(reponse.result);
        setTotalCount(Number(reponse.itemCount.totalFilterCount));
        setPageCount(reponse.itemCount.pageCount);
      } else {
        resetFilter();
      }
    });
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0);
    setActivePage(1);
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };

  const handleChanges = (e) => {
    if (e.target.name === "sectionId") {
      const sectionIdex = sectionList.findIndex(
        (obj) => obj.id === e.target.value
      );
      if (e.target.value == "") {
        setCategoryList([]);
      } else {
        setCategoryList(sectionList[sectionIdex].categories);
      }

      setproductAddForm({
        ...productAddForm,
        categoryId: "",
        sectionId: e.target.value,
      });
    } else {
      setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
    }
    //  getProducts(pageLimit,pageOffset, e.target.name === 'sectionId'?e.target.value : '', e.target.name === 'categoryId'?e.target.value : '');
    //  setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value })
  };

  return (
    <>
      <div className="row m-3">
        <div className="col-md-3">
          <div className="form-group">
            <label>Section</label>
            <select
              name="sectionId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.sectionId}
            >
              <option value={""}>{"--Select Section--"}</option>
              {sectionList &&
                sectionList.length > 0 &&
                sectionList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Category</label>
            <select
              name="categoryId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.categoryId}
            >
              <option value={""}>{"--Select Category--"}</option>
              {categoryList &&
                categoryList.length > 0 &&
                categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="col-md-12 card-1 table-responsive">
          <table className="table table-striped table-bordered border-sm  mt-2">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Images</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            {resultList && resultList.length > 0 ? (
              <tbody>
                {resultList.map((item, i) => {
                  // return <>{item.item.id}</>;
                  return (
                    <TagProductListItem
                      product={item}
                      key={item.item.id}
                      tagId={props.id}
                    />
                  );
                })}
              </tbody>
            ) : (
              <p>No Data found</p>
            )}
          </table>
          <GMCTPagination
            pageCount={pageCount}
            activePage={activePage}
            totalCount={totalCount}
            pageLimit={pageLimit}
            onPageChange={(page) => handlePageChange(page)}
            onLimitChange={(e) => handleLimitChange(e)}
          />
        </div>
      </div>
    </>
  );
}
export default MapTagProducts;
