import { FaEdit, FaEye, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  displayRemoveAlert,
  updateFormInfo,
} from "../../../redux/slice/formUpdateSlice";
import noImage from "../../../assets/no_image.jpg";
import { useRef, useState } from "react";
import { todayBetweenDates } from "../../../utils/dateUtil";
import { couponImageUplaodRequest } from "../../../network/service/ImageUploadService";
import { updateCouponStatusAction } from "../../../network/store/action/CouponResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { useNavigate } from "react-router-dom";
function StoreCouponsItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState(props.item);
  const [file, setFile] = useState("");
  const hiddenFileInput = useRef(null);
  const [itemStatus, setitemStatus] = useState(product.status);

  const onImageClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: product.id,
      fileData: form_data,
    };
    await dispatch(couponImageUplaodRequest(requestBody)).then(
      (response) => {}
    );
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (product.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: product.id,
    };

    await dispatch(updateCouponStatusAction(requestBody)).then((response) => {
      if (response.status == true) {
        setProduct(product, (product.status = status));
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  const onEditClick = () => {
    navigate("/addStoreCoupon", { state: { product } });
  };

  return (
    <>
      <tr style={{ fontWeight: 400, fontSize: "14px" }}>
        <td className="align-middle">
          {product.image_path === null ? null : (
            <div className="cat_mainwrap" onClick={() => onImageClick()}>
              <input
                type="file"
                onChange={handleImageInputChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
              />
              {file ? (
                <>
                  <img
                    src={file}
                    alt={product.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                </>
              ) : product.image_path !== "" ? (
                <img
                  src={product.image_path}
                  alt={product.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              ) : (
                <img
                  src={noImage}
                  alt={product.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              )}
            </div>
          )}
        </td>
        <td>{product.branchName}</td>
        <td>{product.name}</td>
        <td>{product.code}</td>

        <td>
          {product.min_order_value} {" INR"}
        </td>
        <td>
          {product.offer_percentage} {"%"}
        </td>
        <td>
          {"Max. "} {product.max_limit} {" INR"}
        </td>

        {product.days_limit === "DURATION" ? (
          <>
            {" "}
            <td>{"YES"}</td>
            <td>
              <span style={{ color: "black" }}> {product.start_date} </span>
            </td>
            <td>
              <span style={{ color: "#028b92" }}>{product.end_date}</span>
            </td>
            <td>{todayBetweenDates(product.start_date, product.end_date)}</td>
          </>
        ) : (
          <>
            <td>{"NO"}</td>
            <td>{"--"}</td>
            <td>{"--"}</td>
            <td>{"LIVE"}</td>
          </>
        )}

        {product.unique_user === "YES" ? (
          <>
            <td>{product.unique_user}</td> <td>{product.number_of_claims}</td>
          </>
        ) : (
          <>
            <td>{product.unique_user}</td>
            <td>{"--"}</td>
          </>
        )}

        {product.limit_apply === "YES" ? (
          <>
            <td>{product.limit_apply}</td> <td>{product.limit_count}</td>
          </>
        ) : (
          <>
            <td>{product.limit_apply}</td>
            <td>{"--"}</td>
          </>
        )}

        <td>{product.used_count}</td>

        <td className="align-middle">
          {/* <p>{item.status}</p> */}
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              onChange={onStatusChange}
              checked={itemStatus === "YES" ? true : false}
            />
          </div>
        </td>

        <td className="align-middle">
          <div className="d-flex justify-content-center">
            <span className="edit-icon-btn">
              <FaEdit
                onClick={() => {
                  onEditClick();
                }}
              />
            </span>
          </div>
        </td>
      </tr>
    </>
  );
}

export default StoreCouponsItem;
