import { useLocation, useNavigate } from "react-router-dom";
import { listAction as storeListAction } from "../../../network/store/action/BranchResponseAction";
import { isAfterDate, todayDate } from "../../../utils/dateUtil";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  orderItemsReportAction,
  orderReportAction,
} from "../../../network/store/action/ReportsResponseAction";
import SalesItem from "./sales-item";
import OrderSalesItem from "./order-sales-item";

function ItemSales() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // list of items
  const [resultList, setResultList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  const [branches, setBranchList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    storeId: location.state != null ? location.state.storeId : "",
    startDate: location.state != null ? location.state.dated : "",
    endDate: "",
    status: "",
  });

  useEffect(() => {
    getStores();
    loadData();
  }, []);

  useEffect(() => {
    datesValidation();
  }, [filterOptions]);

  const getStores = async () => {
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const datesValidation = () => {
    let to = filterOptions.endDate;

    if (
      filterOptions.startDate.length > 0 &&
      filterOptions.endDate.length > 0 &&
      !isAfterDate(filterOptions.startDate, to)
    ) {
      alert("Select Valid Dates");
    } else {
      loadData();
    }
  };

  const handleChanges = (e) => {
    if (e.target.name === "storeId") {
      branches.findIndex((obj) => obj.id === e.target.value);
      setfilterOptions({
        ...filterOptions,
        storeId: e.target.value,
      });
    } else if (e.target.name === "startDate" && e.target.value === "") {
      setfilterOptions({
        ...filterOptions,
        startDate: e.target.value,
        endDate: "",
      });
    } else {
      setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }
  };
  const loadData = async () => {
    await dispatch(
      orderItemsReportAction(
        {
          startDate: filterOptions.startDate,
          storeId: filterOptions.storeId,
          endDate: filterOptions.endDate,
          status: filterOptions.status,
          productId: "",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  return (
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row">
          <div className="col-md-2">
            <button
              type="button"
              className="mr-auto btn "
              onClick={() => {
                navigate(-1);
              }}
            >
              {"< Back"}
            </button>
            <h3 className="dashboard-title">Sales</h3>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Branch</label>
              <select
                name="storeId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterOptions.storeId}
              >
                <option value={""}>{"All Branches"}</option>
                {branches &&
                  branches.length > 0 &&
                  branches.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div
            className={
              filterOptions.startDate.length > 0 ? "col-md-5" : "col-md-2"
            }
          >
            <Row>
              <Col>
                <FormGroup>
                  <Label for="startDate">Date</Label>
                  <Input
                    type="date"
                    name="startDate"
                    id="startDate"
                    placeholder=""
                    value={filterOptions.startDate}
                    onChange={handleChanges}
                    // min={new Date().toISOString().split("T")[0]}
                  />
                </FormGroup>
              </Col>
              {filterOptions.startDate.length > 0 && (
                <Col>
                  <FormGroup>
                    <Label for="endDate">To Date</Label>
                    <Input
                      type="date"
                      name="endDate"
                      id="endDate"
                      placeholder=""
                      value={filterOptions.endDate}
                      onChange={handleChanges}
                      // min={new Date().toISOString().split("T")[0]}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Status</label>
              <select
                name="status"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterOptions.status}
              >
                <option value={""}>{"--Select Status--"}</option>
                <option value={"0,1,2,3"}>{"Pending"}</option>
                <option value={"4"}>{"Delivered"}</option>
                <option value={"5"}>{"Completed"}</option>
                <option value={"6"}>{"Cancelled"}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 card-1 table-responsive">
        <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Item Name</th>
              <th scope="col">UOM - Price</th>
              <th scope="col" colSpan={4}>
                Sale
              </th>

              <th scope="col">Total Round Off</th>
            </tr>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Order Quantity</th>
              <th scope="col">Total Price</th>
              <th scope="col">Offer</th>
              <th scope="col">Tax</th>

              <th scope="col"></th>
            </tr>
          </thead>

          {resultList && resultList.length > 0 ? (
            <tbody>
              {resultList.map((item, i) => {
                return <OrderSalesItem item={item} key={item.id} />;
              })}
            </tbody>
          ) : (
            <p>No Data found</p>
          )}
        </table>
      </div>
    </main>
  );
}
export default ItemSales;
