import {
  BANNER_API_PATH,
  COUPONS_API_PATH,
  MEDIA_API_PATH,
  ORDER_API_PATH,
  PRODUCT_API_PATH,
  SECTION_API_PATH,
  SUB_CATGORY_API_PATH,
  TAG_API_PATH,
  USER_API_PATH,
} from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/ImageUploadRequest";

export const productImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${PRODUCT_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const userImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${USER_API_PATH.IMAGE_UPLOAD}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const orderInvoiceRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${ORDER_API_PATH.ORDER_INVOICE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const categoryImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${SECTION_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const categoryIconUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${SECTION_API_PATH.ICON_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const subCategoryImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${SUB_CATGORY_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const bannerImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${BANNER_API_PATH.BANNER_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const couponImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${COUPONS_API_PATH.IMAGE_UPLOAD}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const productPromoImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${PRODUCT_API_PATH.PRODUCT_PREVIEW_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const mediaImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${MEDIA_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const tagImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${TAG_API_PATH.TAG_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
