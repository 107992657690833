import { updateCouponStatusRequest } from "../../service/CouponService";

export const updateCouponStatusAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(updateCouponStatusRequest(requestBody));

  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
