import { useState, memo, useRef, useEffect } from "react";
import {
  FaEdit,
  FaEye,
  FaImage,
  FaPlus,
  FaRegEdit,
  FaTrash,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../../redux/slice/formUpdateSlice";
import {
  createUpdateAction,
  updateAllStorePriceAction,
  updateSingleStorePriceAction,
} from "../../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../../../assets/no_image.jpg";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { isDsplayAddProduct } from "../../../utils/userRoles";
import { productPromoImageUplaodRequest } from "../../../network/service/ImageUploadService";
import { Button } from "react-bootstrap";
import { spStatusUpdateAction } from "../../../network/store/action/ProductPriceAction";

const SpPriceAction = (props) => {
  const [product] = useState(props.product);
  const [itemStatus, setitemStatus] = useState(
    product.item.ProductStatus === "NO" ? "NO" : "YES"
  );

  const [storeProductId, setStoreProductId] = useState(
    product.item.storeProductId !== null ? product.item.storeProductId : ""
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onItemStatusChange = async () => {
    let status = "YES";
    if (itemStatus == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    const requestBody = {
      productId: product.item.id,
      storeId: props.storeId,
      status: status,
      id: storeProductId,
    };

    await dispatch(spStatusUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <td className="align-middle" rowSpan={props.rowCount}>
      <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          onChange={onItemStatusChange}
          checked={itemStatus === "YES" ? true : false}
        />
      </div>
    </td>
  );
};

export default SpPriceAction;
