import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { ORDER_API_PATH } from "../../../network/config/apiPaths";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../../redux/slice/formUpdateSlice";

function StoreOrdersItem(props) {
  // const [date,setDate]=useState(new Date());

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order] = useState(props.order);

  // useEffect(()=>{
  //     const time=setInterval(()=>{
  //         setDate(new Date())
  //     },500);
  //     return clearInterval(time)
  // },[])
  function Temp(status) {
    switch (status) {
      case "0":
        return <span style={{ color: "black" }}>Pending</span>;

      case "1":
        return <span style={{ color: "pinkAccent" }}>Accepted</span>;

      case "2":
        return <span style={{ color: "blueAccent" }}>Assigned</span>;

      case "3":
        return <span style={{ color: "pinkAccent" }}>Picked Up</span>;
      case "4":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "5":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "6":
        return <span style={{ color: "red" }}>Cancelled</span>;
      case "7":
        return <span style={{ color: "black" }}>Rejected</span>;
      case "8":
        return (
          <span style={{ color: "0xffcc0d0d" }}>Order Cancel Request</span>
        );
    }
  }

  const storeInfoPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/sInfo", {
      state: {
        storeId: order.storeId,
        storeName: order.storeName,
        storeAddress: order.deliveryCity,
        event: "orders",
      },
    });
  };

  const orderDetailFynctionality = () => {
    //dispatch(updateFormInfo({ displayForm: false, formData: { order } }));

    navigate("/orderDetails", { state: { orderId: order.order_id } });
  };
  return (
    //<>

    //  <div className="d-flex flex-row shadow-lg w-50" style={{borderLeftWidth:10,borderStyle:"solid",borderColor:"white white white red",padding:20, marginLeft:20}}>
    //     <div className="w-50">
    //         <h5>#Ep 133  Pending</h5>
    //         <h6>{date.toLocaleString()}</h6>
    //         <h6>Test</h6>
    //     </div>
    //     <div >
    //        <h6>140 /-</h6>
    //        <h6>COD</h6>
    //     </div>
    //  </div>
    //  <div className="d-flex flex-row shadow-lg w-50" style={{borderLeftWidth:10,borderStyle:"solid",borderColor:"white white white red",padding:20, marginLeft:20}}>
    //     <div className="w-50">
    //         <h5>#Ep 133  Pending</h5>
    //         <h6>{date.toLocaleString()}</h6>
    //         <h6>Test</h6>
    //     </div>
    //     <div >
    //        <h6>140 /-</h6>
    //        <h6>COD</h6>
    //     </div>
    //  </div>
    //  </>
    <tr>
      <td
        onClick={() => {
          orderDetailFynctionality();
        }}
      >
        <Link>#BIYSS0{order.order_id}</Link>
      </td>
      <td>{order.created_at}</td>
      <td>{order.store_price}</td>
      <td>{order.customerName}</td>
      <td>{order.deliveryCity}</td>
      <td>{order.coupon_type}</td>
      <td>{Temp(order.status)}</td>
      <td>{order.payment_type}</td>
    </tr>
  );
}

export default StoreOrdersItem;
