import { SECTION_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const listRequest = (data) => async () => {
  try {
    let result = await getRequest(SECTION_API_PATH.LIST_PATH, null, false);
    return result;
  } catch (error) {
    // TODO Handle Error
  }
};

export const storeCatListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${SECTION_API_PATH.STORE_SECTIONS_PATH}?storeId=${data.storeId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
  }
};

export const createUpdateRequest = (data) => async () => {
  try {
    let result = await postRequest(
      SECTION_API_PATH.CREATE_UPDATE_PATH,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const updateStoreSectionRequest = (data) => async () => {
  try {
    let result = await postRequest(
      SECTION_API_PATH.UPDATE_STORE_SECTION,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
