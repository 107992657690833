import { PRODUCT_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

// export const listRequest = (data) => async () => {
//   try {
//     let result = await getRequest(`${PRODUCT_API_PATH.PRODUCT_PRICE}?limit=${data.limit}&offset=${data.offset}&storeId=${data.storeId}&sectionId=${data.sectionId}&categoryId=${data.categoryId}`, null, false);
//     return result;
//   } catch (error) {
//
//     // TODO Handle Error
//   }
// };

export const priceCreateUpdate = (data) => async () => {
  try {
    let result = await postRequest(PRODUCT_API_PATH.PRODUCT_PRICE, data, false);
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const productStatusUpdate = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.PRODUCT_STATUS,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const storeProductStatusUpdate = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.STORE_PRODUCT_STATUS,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
