import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./products.css";
import {
  formUpdateData,
  formUpdateStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { Link, useNavigate } from "react-router-dom";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
import {
  createUpdateAction,
  tagUpdateAction,
  taglistAction,
} from "../../network/store/action/ProductResponseAction";
import SelectableTagItem from "./selectable-tag-item";
import {
  onClearData,
  selectedItemsStatus,
} from "../../redux/slice/selectedItemsSlice";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { TAG_API_PATH } from "../../network/config/apiPaths";

function ProductTagUpdate(props) {
  // to get api data

  const dispatch = useDispatch();
  // const showRequestForm = useSelector(formUpdateStatus);
  // const formData = useSelector(formUpdateData);
  // const itemInfo = formData.product;
  // list of items
  const [resultList, setResultList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [productId, setProductId] = useState("");
  const selectedIds = useSelector(selectedItemsStatus);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(onClearData());
    setProductId(props.id);
    loadData(props.id);
  }, []);

  const loadData = async (productId) => {
    setLoading(true);
    await dispatch(taglistAction({ productId: productId }, dispatch)).then(
      (response) => {
        setLoading(false);
        if (response.result) {
          setResultList(response.result);
        }
      }
    );
  };

  const clearTags = async () => {
    // setIsLoading(true)
    const data = await axios.delete(
      API_CONFIG.API_HOST +
        TAG_API_PATH.CLEAR_PRODUCT_TAGS_PATH +
        `?productId=${productId}`,
      {
        headers: API_HEADERS,
      }
    );

    // setIsLoading(false)
    navigate(0);
  };
  const onUpdateClick = async () => {
    if (selectedIds && selectedIds.length > 0) {
      let itemObj = {
        productId: productId,
        tagIds: selectedIds.toString(),
      };

      await dispatch(tagUpdateAction(itemObj, dispatch)).then((response) => {
        if (response.result) {
          dispatch(
            updateMessage({
              display: true,
              message: "Tags Updated.",
            })
          );
          dispatch(onClearData());
          navigate(0);
        }
      });
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Select Tags",
        })
      );
    }
  };

  return (
    <>
      {resultList && resultList.length > 0 ? (
        <div className="col-md-12" style={{ padding: "20px" }}>
          {resultList.map((item, i) => {
            return (
              <SelectableTagItem
                item={item}
                key={item.id}
                itemStatus={item.productTagId === "NO" ? false : true}
              />
            );
          })}
        </div>
      ) : isLoading ? (
        <p>Loading Tags</p>
      ) : (
        <p>No Data found</p>
      )}

      <div className="mr-auto col-6 text-end">
        <button
          type="button"
          className="mr-auto btn btn-purple-bg"
          onClick={() => {
            onUpdateClick();
          }}
        >
          Update
        </button>

        <button
          type="button"
          className="mr-auto btn "
          onClick={() => {
            clearTags();
          }}
        >
          Clear Tags
        </button>
      </div>
    </>
  );
}
export default ProductTagUpdate;
