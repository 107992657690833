import { useState, memo, useRef } from "react";
import {
  FaEdit,
  FaEye,
  FaImage,
  FaPlus,
  FaRegEdit,
  FaTag,
  FaTrash,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  displayLargeImage,
  updateFormInfo,
} from "../../../redux/slice/formUpdateSlice";
import { tagAssgnAction } from "../../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../../../assets/no_image.jpg";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { isDsplayAddProduct } from "../../../utils/userRoles";
import { productPromoImageUplaodRequest } from "../../../network/service/ImageUploadService";

const TagProductListItem = (props) => {
  const [product] = useState(props.product);
  const [itemStatus, setitemStatus] = useState(product.item.productTagId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onStatusChange = async () => {
    // let status = "YES";
    // if (product.item.status == "NO") {
    //   status = "YES";
    // } else {
    //   status = "NO";
    // }

    let requestBody = {
      // status: status,
      id: product.item.productTagId === "" ? "" : product.item.productTagId,
      tagId: props.tagId,
      productId: product.item.id,
    };

    await dispatch(tagAssgnAction(requestBody)).then((response) => {
      if (response.status == true) {
        product.item.productTagId = response.result;
        setitemStatus(response.result);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{product.item.id}</p>
      </td>

      <td className="align-middle">
        <div className="cat_mainwrap">
          {product.item.preview_image_path !== "" ? (
            <img
              src={product.item.preview_image_path}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>
      {/* to="/pinfo" state={{ product }} */}
      <td className="align-middle">
        <p>{product.item.name}</p>
      </td>

      <td className="align-middle">
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch  col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "" ? false : true}
          />
        </div>
      </td>
    </tr>
  );
};

export default TagProductListItem;
