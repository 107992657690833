import { useState } from "react";
import { FaEdit, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateUserActon } from "../../../network/store/action/UserResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";

function CustomerItem(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const editItem = () => {
    navigate("/usercu", { state: { item: item } });
  };
  const onStatusChange = async () => {
    let status = "ACTIVE";
    if (item.status == "BLOCKED") {
      status = "ACTIVE";
    } else {
      status = "BLOCKED";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await dispatch(updateUserActon(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(item, (item.status = status));
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };
  return (
    <>
      <tr>
        <td> {item.id} </td>
        <td> {item.city} </td>
        <td>
          {/* <Link to="/customerOrders"></Link> */}
          {item.name}
        </td>
        <td> {item.phone} </td>
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "ACTIVE" ? true : false}
          />
        </div>

        <td className="align-middle">
          <p className="col-lg-12 d-flex justify-content-center">
            <span
              className="edit-icon-btn"
              onClick={() => {
                editItem();
              }}
            >
              <FaRegEdit />
            </span>
            {/* <span className="delete-icon-btn">
            <FaTrash />
          </span> */}
          </p>
        </td>
      </tr>
    </>
  );
}
export default CustomerItem;
