import { Toast } from "react-bootstrap";
import {
  fcmToastStatus,
  toastStatus,
  updateFcmMessage,
  updateMessage,
} from "../redux/slice/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import messageSound from "../assets/sound/slow_spring_board.mp3";

function AppFcmToast() {
  const toastMsgInfo = useSelector(fcmToastStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("toastMsgInfo", toastMsgInfo);

    if (toastMsgInfo.display && document.hasFocus()) {
      try {
        const sound = new Audio(messageSound);
        sound.autoplay = true;
        sound.play();
      } catch (e) {
        console.log(e);
      }
    }
  }, [toastMsgInfo]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 70,
          right: 10,
        }}
      >
        <Toast
          onClose={() =>
            dispatch(updateFcmMessage({ display: false, message: "" }))
          }
          show={toastMsgInfo.display}
          delay={3000}
          autohide
        >
          <Toast.Header className="table" closeButton={false}>
            <strong className="mr-auto">Alert !!</strong>
          </Toast.Header>
          <Toast.Body className="table">
            <div>
              {toastMsgInfo.title}
              {toastMsgInfo.message}
            </div>
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
}

export default AppFcmToast;
