import React, { useEffect, useState } from "react";

import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";
import AttendItem from "./attendance-item";

import { listAction as storeListAction } from "../../network/store/action/BranchResponseAction";
import { useDispatch, useSelector } from "react-redux";
import { formUpdateStatus } from "../../redux/slice/formUpdateSlice";
import {
  attendanceListAction,
  attendanceLogListAction,
  listAction,
} from "../../network/store/action/UserResponseAction";
import { todayDate } from "../../utils/dateUtil";
import AttendLogItem from "./attendance-log-item";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

function UserAttendanceLog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const attnId = location.state != null ? location.state.id : "";
  const userId = location.state != null ? location.state.userId : "";
  const userName = location.state != null ? location.state.userName : "";
  const dated = location.state != null ? location.state.dated : "";
  const branchId = location.state != null ? location.state.branchId : "";

  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  const [userList, setUserList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const [branches, setBranchList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    storeId: branchId,
    startDate: dated, //todayDate(),
    attnId: attnId,
    userId: userId,
  });

  useEffect(() => {
    getStores();
    loadData();
  }, []);

  useEffect(() => {
    loadDUsers();
  }, [filterOptions.storeId]);

  useEffect(() => {
    loadData();
  }, [filterOptions]);

  const loadDUsers = async () => {
    await dispatch(
      listAction(
        {
          orgId: filterOptions.storeId,
          roleId: "3,4,7",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        setUserList(reponse.result);
      } else {
        setUserList([]);
      }
    });
  };

  const getStores = async () => {
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    if (e.target.name === "storeId") {
      branches.findIndex((obj) => obj.id === e.target.value);
      setfilterOptions({
        ...filterOptions,
        storeId: e.target.value,
        userId: "",
      });
    } else {
      setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }
  };
  const loadData = async () => {
    await dispatch(
      attendanceLogListAction(
        {
          fromDate: filterOptions.startDate,
          todateDate: "",
          storeId: filterOptions.storeId,
          attnId: filterOptions.attnId,
          userId: filterOptions.userId,
          dbStatus: "",
        },
        dispatch
      )
    ).then((reponse) => {
      setFilterList([]);

      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  return (
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="col-md-3">
          <div className="form-group">
            <label>Branch</label>
            <select
              name="storeId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={filterOptions.storeId}
            >
              <option value={""}>{"All Branches"}</option>
              {branches &&
                branches.length > 0 &&
                branches.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Employee</label>
            <select
              name="userId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={filterOptions.userId}
            >
              <option value={""}>{"--Select Executive--"}</option>
              {userList &&
                userList.length > 0 &&
                userList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                      {"  / "}
                      {item.role_id == 7 ? (
                        <h6 style={{ color: "green" }}>Delivery Executive</h6>
                      ) : item.role_id == 4 ? (
                        <>Store Executive</>
                      ) : (
                        <>Store Employee</>
                      )}{" "}
                      - {item.db_status}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div
          className={
            filterOptions.startDate.length > 0 ? "col-md-2" : "col-md-2"
          }
        >
          <Row>
            <Col>
              <FormGroup>
                <Label for="startDate">Date</Label>
                <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  placeholder=""
                  value={filterOptions.startDate}
                  onChange={handleChanges}
                  // min={new Date().toISOString().split("T")[0]}
                />
              </FormGroup>
            </Col>
            {/* {filterOptions.startDate.length > 0 && (
              <Col>
                <FormGroup>
                  <Label for="endDate">To Date</Label>
                  <Input
                    type="date"
                    name="endDate"
                    id="endDate"
                    placeholder=""
                    value={filterOptions.endDate}
                    onChange={handleChanges}
                    // min={new Date().toISOString().split("T")[0]}
                  />
                </FormGroup>
              </Col>
            )} */}
          </Row>
        </div>

        <div className="col-md-12 card-1 table-responsive">
          <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Employee Name</th>
                <th scope="col">Branch</th>
                <th scope="col">Work Hours</th>
                <th scope="col">Login Time</th>
                <th scope="col">Login Location</th>
                <th scope="col">Logout Time</th>
                <th scope="col">Logout Location</th>
              </tr>
            </thead>

            {searchVal === "" && resultList && resultList.length > 0 ? (
              <tbody>
                {resultList.map((item, i) => {
                  return <AttendLogItem item={item} key={item.id} />;
                })}
              </tbody>
            ) : filterList.length > 0 ? (
              <tbody>
                {filterList.map((item, i) => {
                  return <AttendLogItem item={item} key={item.id} />;
                })}
              </tbody>
            ) : (
              <p>No Data found</p>
            )}
          </table>
        </div>
      </div>
    </main>
  );
}

export default UserAttendanceLog;
